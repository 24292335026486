
// Глобальные настройки
const Settings = {
	errorMessage: 'Упс! Что-то пошло не так...', // сообщение об ошибке для пользователя
	newStagesOrderCountOnPage: 1000, // кол-во заказов на странице для стадий с новыми заказами (по сколько подгружать)
	oldStagesOrderCountOnPage: 20, // кол-во заказов на странице для стадий со старыми заказами (по сколько подгружать)
	startupPagePath: '/Main/Orders/g2', // адрес стартовой "страницы"
	orderGroupMenuTimeout: 120000, // период обновления меню групп заказов в миллисекундах
	notificationsPanelTimeout: 600000, // период обновления панели уведомлений хидера в миллисекундах
	walletTimeout: 600000, // период обновления панели рабочей информации в хидере в миллисекундах
	minScreenWidthTablet: 768, // минимальная ширина экрана отображения для планшета
	minScreenWidthDesktop: 1080, // минимальная ширина экрана отображения для компьютера
	minScreenWidthWideDesktop: 1440, // минимальная ширина экрана отображения для компьютера с широким экраном
	googleDocsViewerLink: 'https://docs.google.com/viewer?url=', // ссылка на просмотрщик файлов от Google
	maxPersonalDataFileSize: 10485760, // максимальный размер файла в байта, загружаемый для отправки ПДА (иностранцев и ИП)  (10 * 1024 * 1024 = 10485760 байт)
	maxFinalWorkFileSize: 31457280, // максимальный размер файлов в байтах, загружаемых в окне отправки итоговой работы (30 * 1024 * 1024 = 31457280 байт)
	maxChatAttachmentFileSize: 31457280, // максимальный размер файлов в байтах, прикрепляемых к сообщению в чате (30 * 1024 * 1024 = 31457280 байт)
	orderGroupsFiltersVersion: 6, // версия фильтров групп заказов (набор фильтров на страницах групп заказов)
	headerFixingOrderAmount: 1, // версия значения отображения суммы по заказам в хидере
	filtersKeySharedPart: 'mwfilters-', // общая часть в названии ключей в localstorage для хранения последних значений пользовательских фильтров для групп заказов
	signalRHubURL: 'https://im.assistmw.ru', // хаб SignalR для мгновенного обмена сообщениями
	siganlRReconnectionDelay: 10000, // задержка реконкта к хабу SignalR в милисекундах
	suggestionListLength: 5, // количество элементов в списке елементов, предложенных по части названия
	messageCount: 15, // количество сообщений, которое будет загружено в чат за один раз
	defaultSource: 'default', // источник регистрации анкет автора по умолчанию
	emailRegExp: /^([a-z0-9_.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i, // регулярное выражение проверки корректности электронной почты
	phoneRegExp: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/i, // регулярное выражение проверки корректности номера телефона
	idPayServiceRegistration: '0', // тип регистрации автора в платёжном сервисе 0 - Юмани, 1 - TempoJob, 2 - Qugo
	googleDocsViewableMaterialExtensions: [
		'.doc',
		'.docx',
		'.xls',
		'.xlsx',
		'.ppt',
		'.pptx',
		'.pdf',
		'.pages',
		'.ai',
		'.psd',
		'.tiff',
		'.dxf',
		'.svg',
		'.eps',
		'.ps',
		'.ttf',
		'.xps',
		'.rtf',
		'.odt',
		'.sxw',
		'.ssv',
		'.sxc',
		'.ods',
		'.sxi',
		'.odp',
		'.wpd',
	], // расширения материалов, доступных для просмотра в Google Docs
	executionStandartFileLink:
		'https://docs.google.com/document/d/1qDdBo23tySmhNezoxsMno4_PAgjukS2ElAfqku_EzhY/edit#', // ссылка на файл стандартов выполнения
	telegramUrl: 'https://t.me/', // основа ссылки на чат в телеграме
	whatsAppUrl: 'https://wa.me/', // основа ссылки на чат в ватсапп
	searchBarCharMaxCount: 128, // максимальное количество символов в строке поиска
	searchBarCharMinCount: 4, // минимальное количество символов в строке поиска
	formRegistrationChecksLink: "https://yookassa.ru/self-employed-boarding?agentId=201147&success_url=https://partner.multiwork.org/Main/Profile/PaymentInfo?formType=1&fail_url=https://partner.multiwork.org/Main/Profile/PaymentInfo", // ссылка на форму регистрации чеков
	formRegistrationCard: "https://yookassa.ru/cardauth?url_error=https://partner.multiwork.org/Main/Profile/PaymentInfo&url_success=https://partner.multiwork.org/Main/Profile/PaymentInfo?formType=1&identify=true", // форма добавления карты
	agentId: 206741, // идентификатор платежного шлюза ЮМани
	countries: [ // список стран для выбора кода номера телефона
		{ code: '+7', shortCode: 'ru', name: 'Россия', mask: '999 999-99-99' },
		{ code: '+375', shortCode: 'BY', name: 'Беларусь', mask: '99 999-99-99' },
		{ code: '+380', shortCode: 'UA', name: 'Украина', mask: '99 999 9999' },
		{ code: '+374', shortCode: 'AM', name: 'Армения', mask: '99 999 999' },
		{ code: '+994', shortCode: 'AZ', name: 'Азербайджан', mask: '99 999 99 99' },
		{ code: '+995', shortCode: 'GE', name: 'Грузия', mask: '999 99 99 99' },
		{ code: '+996', shortCode: 'KG', name: 'Киргизия', mask: '999 99 99 99' },
		{ code: '+992', shortCode: 'TJ', name: 'Таджикистан', mask: '99 999 9999' },
		{ code: '+993', shortCode: 'TM', name: 'Туркмения', mask: '99 999 9999' },
		{ code: '+998', shortCode: 'UZ', name: 'Узбекистан', mask: '99 999 9999' },
		{ code: '+77', shortCode: 'KZ', name: 'Казахстан', mask: '999 999-99-99' },
		{ code: '+61', shortCode: 'AU', name: 'Австралия', mask: '9999 999 999' },
		{ code: '+43', shortCode: 'AT', name: 'Австрия', mask: '999 999 9999' },
		{ code: '+54', shortCode: 'AR', name: 'Аргентина', mask: '99 9999-9999' },
		{ code: '+376', shortCode: 'AD', name: 'Андорра', mask: '999 999' },
		{ code: '+55', shortCode: 'BR', name: 'Бразилия', mask: '99 99999-9999' },
		{ code: '+32', shortCode: 'BE', name: 'Бельгия', mask: '99 999 99 99' },
		{ code: '+973', shortCode: 'BH', name: 'Бахрейн', mask: '9999 9999' },
		{ code: '+359', shortCode: 'BG', name: 'Болгария', mask: '99 999 999' },
		{ code: '+673', shortCode: 'BN', name: 'Бруней', mask: '99 999 99' },
		{ code: '+44', shortCode: 'GB', name: 'Великобритания', mask: '9999 999 999' },
		{ code: '+84', shortCode: 'VN', name: 'Вьетнам', mask: '99 9999 9999' },
		{ code: '+58', shortCode: 'VE', name: 'Венесуэла', mask: '999-9999999' },
		{ code: '+379', shortCode: 'VA', name: 'Ватикан', mask: '999 999 999' },
		{ code: '+49', shortCode: 'DE', name: 'Германия', mask: '9999 999 999' },
		{ code: '+30', shortCode: 'GR', name: 'Греция', mask: '999 999 9999' },
		{ code: '+220', shortCode: 'GM', name: 'Гамбия', mask: '999 9999' },
		{ code: '+350', shortCode: 'GI', name: 'Гибралтар', mask: '999 9999' },
		{ code: '+504', shortCode: 'HN', name: 'Гондурас', mask: '9999-9999' },
		{ code: '+45', shortCode: 'DK', name: 'Дания', mask: '99 99 99 99' },
		{ code: '+243', shortCode: 'CD', name: 'Демократическая Республика Конго', mask: '999 999 999' },
		{ code: '+253', shortCode: 'DJ', name: 'Джибути', mask: '99 99 99 99' },
		{ code: '+20', shortCode: 'EG', name: 'Египет', mask: '99 9999 9999' },
		{ code: '+967', shortCode: 'YE', name: 'Емен', mask: '99 999 999' },
		{ code: '+260', shortCode: 'ZM', name: 'Замбия', mask: '99 9999 9999' },
		{ code: '+39', shortCode: 'IT', name: 'Италия', mask: '999 9999999' },
		{ code: '+34', shortCode: 'ES', name: 'Испания', mask: '999 999 999' },
		{ code: '+354', shortCode: 'IS', name: 'Исландия', mask: '999 9999' },
		{ code: '+91', shortCode: 'IN', name: 'Индия', mask: '99999-99999' },
		{ code: '+353', shortCode: 'IE', name: 'Ирландия', mask: '99 999 9999' },
		{ code: '+62', shortCode: 'ID', name: 'Индонезия', mask: '999-999-999' },
		{ code: '+98', shortCode: 'IR', name: 'Иран', mask: '999 9999999' },
		{ code: '+962', shortCode: 'JO', name: 'Иордания', mask: '99 9999 9999' },
		{ code: '+86', shortCode: 'CN', name: 'Китай', mask: '999 9999 9999' },
		{ code: '+1', shortCode: 'US', name: 'Канада', mask: '(999) 999-9999' },
		{ code: '+53', shortCode: 'CU', name: 'Куба', mask: '99 9999999' },
		{ code: '+238', shortCode: 'CV', name: 'Кабо-Верде', mask: '99 99 99' },
		{ code: '+686', shortCode: 'KI', name: 'Кирибати', mask: '99 99 99' },
		{ code: '+57', shortCode: 'CO', name: 'Колумбия', mask: '999 999 999' },
		{ code: '+242', shortCode: 'CG', name: 'Конго', mask: '99 999 999' },
		{ code: '+357', shortCode: 'CY', name: 'Кипр', mask: '99 999 999' },
		{ code: '+965', shortCode: 'KW', name: 'Кувейт', mask: '9999 9999' },
		{ code: '+254', shortCode: 'KE', name: 'Кения', mask: '999 999 999' },
		{ code: '+371', shortCode: 'LV', name: 'Латвия', mask: '99 999 999' },
		{ code: '+423', shortCode: 'LI', name: 'Лихтенштейн', mask: '999 999' },
		{ code: '+370', shortCode: 'LT', name: 'Литва', mask: '99 999 999' },
		{ code: '+231', shortCode: 'LR', name: 'Либерия', mask: '99 9999 9999' },
		{ code: '+961', shortCode: 'LB', name: 'Ливан', mask: '99 999 999' },
		{ code: '+52', shortCode: 'MX', name: 'Мексика', mask: '99 9999 9999' },
		{ code: '+212', shortCode: 'MA', name: 'Марокко', mask: '99 9999 9999' },
		{ code: '+960', shortCode: 'MV', name: 'Мальдивы', mask: '999-9999' },
		{ code: '+258', shortCode: 'MZ', name: 'Мозамбик', mask: '99 9999 9999' },
		{ code: '+222', shortCode: 'MR', name: 'Мавритания', mask: '99 99 99 99' },
		{ code: '+356', shortCode: 'MT', name: 'Мальта', mask: '99 999 999' },
		{ code: '+596', shortCode: 'MQ', name: 'Мартиника', mask: '99 99 99 99' },
		{ code: '+31', shortCode: 'NL', name: 'Нидерланды', mask: '99 999 9999' },
		{ code: '+64', shortCode: 'NZ', name: 'Новая Зеландия', mask: '999 999 9999' },
		{ code: '+264', shortCode: 'NA', name: 'Намибия', mask: '99 999 9999' },
		{ code: '+977', shortCode: 'NP', name: 'Непал', mask: '99 999 9999' },
		{ code: '+227', shortCode: 'NE', name: 'Нигер', mask: '99 99 99 99' },
		{ code: '+687', shortCode: 'NC', name: 'Новая Каледония', mask: '99 99 99' },
		{ code: '+971', shortCode: 'AE', name: 'ОАЭ', mask: '99 999 9999' },
		{ code: '+968', shortCode: 'OM', name: 'Оман', mask: '9999 9999' },
		{ code: '+682', shortCode: 'CK', name: 'Острова Кука', mask: '99 999' },
		{ code: '+351', shortCode: 'PT', name: 'Португалия', mask: '99 999 9999' },
		{ code: '+48', shortCode: 'PL', name: 'Польша', mask: '99 999 9999' },
		{ code: '+92', shortCode: 'PK', name: 'Пакистан', mask: '999-9999999' },
		{ code: '+680', shortCode: 'PW', name: 'Палау', mask: '999 9999' },
		{ code: '+675', shortCode: 'PG', name: 'Папуа - Новая Гвинея', mask: '99 999 999' },
		{ code: '+595', shortCode: 'PY', name: 'Парагвай', mask: '999 999 999' },
		{ code: '+1', shortCode: 'US', name: 'США', mask: '(999) 999-9999' },
		{ code: '+966', shortCode: 'SA', name: 'Саудовская Аравия', mask: '99 999 9999' },
		{ code: '+221', shortCode: 'SN', name: 'Сенегал', mask: '77 123 45 67' },
		{ code: '+248', shortCode: 'SC', name: 'Сейшелы', mask: '999-9999' },
		{ code: '+963', shortCode: 'SY', name: 'Сирия', mask: '99 999 999' },
		{ code: '+252', shortCode: 'SO', name: 'Сомали', mask: '99 999 999' },
		{ code: '+597', shortCode: 'SR', name: 'Суринам', mask: '999 999 999' },
		{ code: '+90', shortCode: 'TR', name: 'Турция', mask: '999 999 9999' },
		{ code: '+66', shortCode: 'TH', name: 'Таиланд', mask: '99 999 9999' },
		{ code: '+255', shortCode: 'TZ', name: 'Танзания', mask: '999 999 999' },
		{ code: '+228', shortCode: 'TG', name: 'Того', mask: '99 99 99 99' },
		{ code: '+676', shortCode: 'TO', name: 'Тонга', mask: '99 99 99' },
		{ code: '+33', shortCode: 'FR', name: 'Франция', mask: '99 99 99 99 99' },
		{ code: '+63', shortCode: 'PH', name: 'Филиппины', mask: '999 999 9999' },
		{ code: '+358', shortCode: 'FI', name: 'Финляндия', mask: '99 999 9999' },
		{ code: '+679', shortCode: 'FJ', name: 'Фиджи', mask: '999 9999' },
		{ code: '+385', shortCode: 'HR', name: 'Хорватия', mask: '99 999 999' },
		{ code: '+56', shortCode: 'CL', name: 'Чили', mask: '99 9999 9999' },
		{ code: '+46', shortCode: 'SE', name: 'Швеция', mask: '99-999 9999' },
		{ code: '+41', shortCode: 'CH', name: 'Швейцария', mask: '99 999 99 99' },
		{ code: '+94', shortCode: 'LK', name: 'Шри-Ланка', mask: '99 999 9999' },
		{ code: '+593', shortCode: 'EC', name: 'Эквадор', mask: '99 999 9999' },
		{ code: '+27', shortCode: 'ZA', name: 'ЮАР', mask: '99 999 9999' },
		{ code: '+82', shortCode: 'KR', name: 'Южная Корея', mask: '99-9999-9999' },
		{ code: '+211', shortCode: 'SS', name: 'Южный Судан', mask: '99 999 999' },
		{ code: '+81', shortCode: 'JP', name: 'Япония', mask: '99-9999-9999' },
	],
}

export default Settings
